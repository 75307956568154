import { LINK_VERICHAINS } from 'config/constants';
import styled from 'styled-components'

export const Footer = () => {
    return (
        <CsWrapperFooter>
            <CsLayoutFooter>
                <About />
                <Headquarters />
            </CsLayoutFooter>
        </CsWrapperFooter>
    )
}

const About = () => {
    return (
        <CsAbout>
            <a href="https://tss.org.vn/chung-chi-quy" target="_blank" rel="noreferrer">
                <img src="/images/ccqFundgo/logo-tss.png?version=1.1" alt="logo-tss" className='logo-tss' />
            </a>
            <a href={LINK_VERICHAINS} target="_blank" rel="noreferrer">
                <img src="/images/ccqFundgo/verichain.png?version=1.2" alt="verichain" className='verichain' />
            </a>
            <h4>Giới thiệu</h4>
            <p>Trung tâm Quản lý Tài sản số - Digital Asset Management Center,
                gọi tắt là TSS, là đơn vị đầu tiên tại Việt Nam được Nhà nước công nhận tư cách pháp nhân để thực hiện nhiệm vụ thúc đẩy,
                tổ chức các hoạt động số hóa,
                nghiên cứu khoa học và quản lý tài sản số theo định hướng kinh tế số của Chính phủ.</p>
        </CsAbout>
    )
};

const Headquarters = () => {
    return (
        <CsHeadquarters>
            <a href={LINK_VERICHAINS} target="_blank" rel="noreferrer">
                <img src="/images/ccqFundgo/verichain.png?version=1.2" alt="verichain" className='logo' />
            </a>
            <h4>Trụ sở chính</h4>
            <div className='description'>
                <p>124 Huỳnh Tấn Phát, Phường Tân Thuận Tây, Quận 7, Thành phố Hồ Chí Minh.</p>
                <p>Hotline: 0899 165 169</p>
                <p>Email: support@tss.org.vn</p>
                <div className="social">
                    <a href="https://www.facebook.com/TSS.ORG.VN" target="_blank" rel="noreferrer">
                        <img src="/images/ccqFundgo/fb.png" alt="fb" />
                    </a>
                    <a href="https://twitter.com/TssTai" target="_blank" rel="noreferrer">
                        <img src="/images/ccqFundgo/twitter.png" alt="twitter" />
                    </a>
                    <a href="https://t.me/TTQLTSS" target="_blank" rel="noreferrer">
                        <img src="/images/ccqFundgo/telegram.png" alt="telegram" />
                    </a>
                </div>
            </div>
        </CsHeadquarters>
    )
}

const CsHeadquarters = styled.div`
    img.logo{
            width: 330px;
            margin-bottom: 40px;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    h4{
        margin-bottom: 28px;
        color: #F9A138;
        font-size: 24px;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 14px;
        }
    }
    .description{
        display: flex;
        flex-direction: column;
        gap: 14px;
        @media screen and (max-width: 1024px) {
            gap: 8px;
        }
        .social{
            display: flex;
            gap: 24px;
        }
    }
    p{
        text-align: justify;
        color: #ffff;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 1024px) {
            font-size: 13px;
            max-width: 100%;
        }
    }
`

const CsAbout = styled.div`
    .verichain{
        display: none;
        @media screen and (max-width: 1024px) {
            width: 230px;
            display: block;
            margin-bottom: 20px;
        }
    }
    img.logo-tss{
        width: 162px;
        margin-bottom: 40px;
        @media screen and (max-width: 1024px) {
            width: 120px;
            margin-bottom: 24px;
        }
    }
    h4{
        margin-bottom: 28px;
        color: #F9A138;
        font-size: 24px;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 14px;
        }
    }
    p{
        max-width: 560px;
        text-align: justify;
        color: #ffff;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 1024px) {
            font-size: 13px;
            max-width: 100%;
        }
    }
`

const CsWrapperFooter = styled.div`
    background:#13486B;
    width: 100%;
    padding: 35px 82px;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }
`

const CsLayoutFooter = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 36px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`
