import BigNumber from "bignumber.js";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import { ERC20_ABI } from "config/abi/erc20";
import { ccqSupportItems } from "config/constants/ccqSupport/constants/type";
import multicall from "utils/multicall";
import { DataBalance, Items, ListAllowance, ListItems } from "./type";

export const fetchListOrder = async (ccqConfig: ccqSupportItems[], account: string, chainId: number): Promise<ListItems> => {
    try {
        if (account?.length > 0) {
            const calls = [];
            const callsPaused = [];
            for (let index = 0; index < ccqConfig?.length; index++) {
                calls.push({
                    address: ccqConfig[index]?.ccqWrrapped?.address,
                    name: 'getListingP2P',
                    params: [account]
                })
            }
            for (let index = 0; index < ccqConfig?.length; index++) {
                callsPaused.push({
                    address: ccqConfig[index]?.ccqWrrapped?.address,
                    name: 'paused',
                    params: []
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);

            const resulCallsPaused = await multicall(ccqWrappedAbi, callsPaused, chainId);
            const listItems = []
            if (result?.length > 0) {
                for (let index = 0; index < result?.length; index++) {
                    for (let subIndex = 0; subIndex < result[index][0]?.length; subIndex++) {
                        listItems.push({
                            isSelling: result[index][0][subIndex]?.isSelling,
                            user: result[index][0][subIndex]?.user,
                            dateTrading: Number(new BigNumber(result[index][0][subIndex]?.sellDate?.toString()).toString()),
                            amountTrading: Number(new BigNumber(result[index][0][subIndex]?.amount?.toString()).toString()),
                            addressTrading: result[index][0][subIndex]?.user,
                            nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                            nftId: Number(new BigNumber(result[index][0][subIndex]?.tokenId?.toString()).toString()),
                            price: Number(new BigNumber(result[index][0][subIndex]?.price?.toString()).dividedBy(1E18).toString()),
                            orderId: Number(new BigNumber(result[index][0][subIndex]?.orderId?.toString()).toString()),
                            paused: resulCallsPaused[index][0]
                        })
                    }
                }
            }
            const resultListItemOrder = await Promise.all(listItems.map(async (item) => {
                const getTermByTime = await multicall(ccqWrappedAbi, [{ address: item.nftAddress, name: 'getTermByTime', params: [item.dateTrading] }], chainId);
                return {
                    ...item,
                    termByTime: new BigNumber(getTermByTime[0]?.toString()).toNumber(),
                }
            }));
            return {
                listItemOrder: resultListItemOrder
            }
        }
        return {
            listItemOrder: []
        }
    }
    catch (error) {
        console.log(error)
        return {
            listItemOrder: []
        }
    }
}

export const fetchDataUser = async (tokenAddres: string, activeCCQ: ccqSupportItems[], account: string, chainId: number): Promise<ListAllowance> => {
    try {
        const calls = []
        for (let index = 0; index < activeCCQ?.length; index++) {
            calls.push(
                {
                    address: tokenAddres,
                    name: 'allowance',
                    params: [account, activeCCQ[index]?.ccqWrrapped?.address]
                }
            )
        }
        const result = await multicall(ERC20_ABI, calls, chainId)
        const data = []
        if (result?.length > 0) {
            for (let index = 0; index < result?.length; index++) {
                data.push(
                    {
                        nftAddress: activeCCQ[index]?.ccqWrrapped?.address,
                        allowance: new BigNumber(result[index].toString()).toString()
                    }
                )
            }
        }
        return {
            listAllowanc: data
        }

    } catch (error) {
        return {
            listAllowanc: []
        }
    }
};

export const fetchDataBalance = async (tokenAddres: string, account: string, chainId: number): Promise<DataBalance> => {
    try {
        const calls = [
            {
                address: tokenAddres,
                name: 'balanceOf',
                params: [account]
            }
        ]
        const [result] = await multicall(ERC20_ABI, calls, chainId)
        return {
            balance: new BigNumber(result?.toString()).toString()
        }
    } catch (error) {
        return {
            balance: "0"
        }
    }
}


export const fetchVestingMap = async (listItem: Items[], chainId: number, account: string): Promise<ListItems> => {
    try {
        const calls = [];
        const currTime = Date.now();
        for (let index = 0; index < listItem?.length; index++) {
            calls.push(
                {
                    address: listItem[index]?.nftAddress,
                    name: 'getMyVestList',
                    params: [listItem[index]?.nftAddress, listItem[index]?.nftId]
                }
            )
        }
        const resultGetMyVestList = await multicall(ccqWrappedAbi, calls, chainId);

        const resultParse = listItem?.map((item, key) => {
            const resultVestingMap = resultGetMyVestList[key][0]?.map((res, i) => {
                return {
                    amount: new BigNumber(res?.amount.toString()).toString(),
                    isVested: res?.isVested,
                    vestDate: Number(new BigNumber(res?.vestDate.toString()).toString()),
                    intervestPayed: new BigNumber(res?.intervestPayed.toString()).toString(),
                    termTime: i + 1
                }
            });
            const nextVestingMap = resultVestingMap.find((d) => currTime < d?.vestDate * 1000);
            const currencyTermTime = nextVestingMap ? nextVestingMap.termTime : 0;

            return {
                ...item,
                vestingMap: resultVestingMap,
                currencyTermTime,
                isBuyP2P: currencyTermTime === item.termByTime,
            }
        });
        return {
            listItemOrder: resultParse
        }
    } catch (error) {
        console.log(error)
        return {
            listItemOrder: []
        }
    }
};