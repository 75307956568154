import { useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { fetchStatusLoading, listItemHistory, getTotalItem } from './actions'
import { fetchListHistory } from './fetchData'


export const UseCoreListHistory = (account: string, pageSize: number, pageIndex: number, fromData: string, toData: string, searchData: string, orderTypeData: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>()
    useSlowRefreshEffect(() => {
        const getDataListHistory = async () => {
            try {
                dispatch(fetchStatusLoading({ isLoading: true }))
                const result = await fetchListHistory(account, pageSize, pageIndex, fromData, toData, searchData, orderTypeData, chainId)

                dispatch(listItemHistory({ listItemHistory: result?.listItemHistory }))
                dispatch(getTotalItem({ totalItem: result?.totalItem }))
                dispatch(fetchStatusLoading({ isLoading: false }))
            } catch (e) {
                console.log(e)
            }
        }
        if (account?.length) {
            getDataListHistory()
        } else {
            dispatch(listItemHistory({ listItemHistory: [] }))
        }
    }, [account, dispatch, fromData, orderTypeData, pageIndex, pageSize, searchData, toData])
}


export const GetListHistory = () => {
    const data = useSelector<AppState, AppState['history']>((state) => state.history)
    return data
}

export const GetItemHistory = () => {
    const data = useSelector<AppState, AppState['history']>((state) => state.history)
    const itemHistoryValue = data?.itemHistory
    return itemHistoryValue
}