import { ChainId } from "@pancakeswap/sdk"
import contracts from "config/constants/contracts"
import { bscTokens, bscTestnetTokens, onusMainnetTokens } from "@pancakeswap/tokens"
import { getAddress } from "./addressHelpers"

export function renderMarketplaceAddress(chainId) {
    return getAddress(contracts.ccqMarketplace, chainId)
}
export function renderToken(chainId: number) {
    switch (chainId) {
        case ChainId.BSC:
            return bscTokens.VNDC;
        case ChainId.ONUS:
            return onusMainnetTokens.vndc;
        default:
            return bscTestnetTokens.vndc;
    }
}