
import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, OpenNewIconFundgo, Text } from "@pancakeswap/uikit";
import React, { useMemo } from "react";
import { ItemWithVestingMap } from "state/Order/type";
import styled from "styled-components";
import { convertDate } from "utils/converDate";

import { CsModal, CsTextMobile, CustomLink, TextTitle } from "./styles";

interface DetailProps {
    onDismiss?: () => void;
    sAccount?: (seller) => string;
    handleOpenLink?: (seller) => void;
    item: ItemWithVestingMap,
    account: string,
    chainId: number
}

const DetailModal: React.FC<DetailProps> = ({ onDismiss, sAccount, handleOpenLink, item, account, chainId }) => {
    const { t } = useTranslation();
    function handleClickOut() {
        onDismiss();
    }

    const totalVested = useMemo(() => {
        return item?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.vestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.vestingMap?.length
    }, [item?.vestingMap?.length])

    return (
        <CsModal title="" onDismiss={onDismiss}>
            <Flex width="100%" height="auto" flexDirection="column" style={{ gap: "10px" }}>
                <Flex width="100%" justifyContent="center">
                    <TextTitle bold>Thông tin chi tiết</TextTitle>
                </Flex>

                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Loại lệnh</CsTextMobile>
                    <CsText bold width="15%" isOnSale={item?.isSelling}>
                        {item?.isSelling ? t(`Bán`) : t(`Mua`)}
                    </CsText>
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Kỳ đã nhận /Tổng kỳ</CsTextMobile>
                    <Text fontSize="12px" bold>
                        {totalVested}/{totalVestingMap}
                    </Text>
                </Flex>
                <ItemDetail title="Ngày giao dịch" value={convertDate(item?.dateTrading * 1000)} />
                <ItemDetail title="Số lượng giao dịch" value={item?.amountTrading} />
                <ItemDetail
                    title="Tổng giá trị giao dịch (VND)"
                    value={
                        Number(item?.price).toLocaleString("en", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })}
                />
                <ItemDetail title="Trạng thái" value="Đang chờ xác nhận" />
                <ItemDetail
                    title="Địa chỉ ví giao dịch thỏa thuận"
                    value={
                        <CustomLink
                            style={{ textAlign: "right" }}
                            onClick={() => handleOpenLink(item?.addressTrading)}
                        >
                            <Text style={{ fontWeight: "600" }}>{sAccount(item?.addressTrading)}</Text>
                            <OpenNewIconFundgo />
                        </CustomLink>
                    }
                />
                <Flex mt="1rem">
                    <CSButton onClick={handleClickOut} height="42px" width="100%" style={{ borderRadius: "8px" }}>
                        Thoát
                    </CSButton>
                </Flex>
            </Flex>
        </CsModal>
    );
};

const ItemDetail = ({ title, value }: { title: string; value: any }) => {
    return (
        <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
            <CsTextMobile color="textSubtle">{title}</CsTextMobile>
            <CsTextMobile bold>{value}</CsTextMobile>
        </Flex>
    );
};

const CSButton = styled(Button)`
    transition: 0.5s;
    &:hover {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
`;

const CsText = styled(Text) <{ isOnSale: boolean }>`
    color: ${({ theme, isOnSale }) => (isOnSale ? theme.colors.red : "#81D401")} !important;
    text-align: right;
    font-size: 16px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;
export default DetailModal;
