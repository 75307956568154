import { Button, Flex, PlayIcon, Skeleton, Text, useModal } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { ListAssestItem, VestingItem } from "state/asset/type";
import styled from "styled-components";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import DetailModal from "./ModalDetail";
import ProfitReceivedModal from "./ProfitReceivedModal";
import ReceivedAll from "./ReceivedAll";
import SellModal from "./SellModal";

interface Props {
    item: ListAssestItem;
    windowSize: number;
    account: string;
    chainId: number;
    vestingMap: VestingItem[],
    currentTime: number,
    indexRow: number
}
const Row: React.FC<React.PropsWithChildren<Props>> = ({ item, windowSize, account, chainId, vestingMap, currentTime, indexRow }) => {

    const yieldsReceived = useMemo(() => {
        return new BigNumber(vestingMap[0]?.vestAmount).multipliedBy(item?.nftBalance).toString()
    }, [item?.nftBalance, vestingMap]) // lợi suất sắp nhận

    const [onProfitReceivedModal] = useModal(
        <ProfitReceivedModal
            chainId={chainId}
            account={account}
            price={item?.denominations}
            profit={`${Number(item?.profit)}%`}
            currentyield={item?.vestingMap[0]?.vestAmount}
            origininvest={Number(item?.denominations) * Number(item?.nftBalance)}
            interestPayDate={item?.vestingMap[0]?.nextInterestPaymentDate}
            dayleft={getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)}
            windowSize={windowSize}
            title="Thông tin Chứng chỉ Quỹ"
            dataUser={item}
        />,
        true,
        true,
        `modal_profitreceivedmodal_${indexRow}`
    );
    const [onReceivedAll] = useModal(
        <ReceivedAll
            chainId={chainId}
            account={account}
            price={item?.denominations}
            profit={`${Number(item?.profit)}%`}
            currentyield={item?.vestingMap[0]?.vestAmount}
            origininvest={Number(item?.denominations) * Number(item?.nftBalance)}
            interestPayDate={item?.vestingMap[0]?.nextInterestPaymentDate}
            dayleft={getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)}
            windowSize={windowSize}
            title="Thông tin Chứng chỉ Quỹ"
            dataUser={item}
        />,
        true,
        true,
        `modal_receivedAll_${item?.nftAddress}_${indexRow}`
    );
    const [onSellModal] = useModal(
        <SellModal
            item={item}
            windowSize={windowSize}
            title="Thông tin Chứng chỉ Quỹ"
            account={account}
            chainId={chainId}
        />,
        true,
        true,
        `modal_sell_${item?.nftAddress}_${indexRow}`
    );

    const totalVested = useMemo(() => {
        return item?.fullVestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.fullVestingMap])

    const totalVestingMap = useMemo(() => {
        return item?.fullVestingMap?.length
    }, [item?.fullVestingMap?.length])

    const [openDetailModal] = useModal(
        <DetailModal
            item={item}
            totalVested={totalVested}
            totalVestingMap={totalVestingMap}
            yieldsReceived={yieldsReceived}
        />,
        true,
        true,
        `modal_detals_${item?.nftAddress}_${indexRow}`
    );
    const totalPrice = useMemo(() => {
        return Number(item.denominations) * Number(item?.nftBalance);
    }, [item])
    const isTimeCanClaim = currentTime > vestingMap[0]?.nextInterestPaymentDate * 1000;

    return (
        <>
            {windowSize > 768 ? (
                <WrapperTable>
                    <WrapperTableContent>
                        <WrapItem>
                            <Text width="9.4%">{item?.nftBalance}</Text>
                            <Text width="9.4%">
                                {Number(item?.denominations).toLocaleString("en", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </Text>
                            <Text width="9.4%">{item?.intervestTerm} tháng</Text>
                            <Text width="9.4%">{totalVested}/{totalVestingMap}</Text>
                            <Text width="9.4%"> {Number(item?.profit)}%</Text>
                            <Flex width="9.4%" justifyContent="center">
                                {(item?.isExpire && vestingMap?.length === 0) ?
                                    convertDate(item?.expireDate * 1000)
                                    :
                                    <Text fontSize="14px">{convertDate(vestingMap[0]?.nextInterestPaymentDate * 1000)}</Text>
                                }
                            </Flex>
                            {(item?.isExpire && vestingMap?.length === 0) ?
                                <Text width="9.4%">
                                    0 {UNIT_DATE}
                                </Text>
                                :
                                <>
                                    {new BigNumber(vestingMap[0]?.nextInterestPaymentDate * 1000).isNaN() ?
                                        <Flex width="10%" justifyContent="center"><Skeleton width="42px" /></Flex>
                                        :
                                        <Text width="10%">{getDateBetweenTwoDates(vestingMap[0]?.nextInterestPaymentDate * 1000)} {UNIT_DATE}</Text>
                                    }
                                </>
                            }
                            {(item?.isExpire && vestingMap?.length === 0) ?
                                <Text width="9.4%">
                                    0
                                </Text>
                                :
                                <>
                                    {new BigNumber(vestingMap[0]?.vestAmount).isNaN() ?
                                        <Flex width="10%" justifyContent="center"><Skeleton width="42px" /></Flex>
                                        :
                                        <Text width="10%">
                                            {" "}
                                            {Number(yieldsReceived).toLocaleString("en", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })}
                                        </Text>
                                    }
                                </>
                            }
                            <Text width="9.4%">
                                {totalPrice.toLocaleString("en", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </Text>
                            <WraperBtnSell>
                                {vestingMap?.length > 0 ?
                                    <>
                                        {currentTime > Number(vestingMap[0]?.nextInterestPaymentDate) * 1000 ? (
                                            <CsButtonReceiProfit
                                                disabled={!isTimeCanClaim}
                                                onClick={onProfitReceivedModal}
                                            >
                                                Nhận lợi suất
                                            </CsButtonReceiProfit>
                                        ) : (
                                            <CsButton onClick={onSellModal} disabled={item?.issueDate * 1000 > Date.now()}>Bán</CsButton>
                                        )}
                                    </>
                                    :
                                    <>
                                        {item?.isExpire &&
                                            <CsButton onClick={onReceivedAll}>Đáo hạn</CsButton>
                                        }
                                    </>
                                }
                            </WraperBtnSell>
                        </WrapItem>
                    </WrapperTableContent>
                </WrapperTable>
            ) : (
                <WrapperTableMobile>
                    <WrapItemMobile>
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            mt="0.5rem"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <Flex width="25%">
                                <PlayIcon width="20px" style={{ cursor: "pointer" }} onClick={openDetailModal} />
                                <Text fontSize="12px" color="textSubtle">
                                    SL:{" "}
                                </Text>
                                <Text>&nbsp;{item?.nftBalance}</Text>
                            </Flex>
                            <Flex width="25%" justifyContent="center">
                                <CsTextMonth>{item?.intervestTerm} tháng</CsTextMonth>
                            </Flex>
                            <Flex width="25%" justifyContent="center">
                                {(item?.isExpire && vestingMap?.length === 0) ?
                                    <Text>
                                        0 {UNIT_DATE}
                                    </Text>
                                    :
                                    <>
                                        {new BigNumber(vestingMap[0]?.nextInterestPaymentDate * 1000).isNaN() ?
                                            <Skeleton width="42px" />
                                            :
                                            <Text>{getDateBetweenTwoDates(vestingMap[0]?.nextInterestPaymentDate * 1000)} {UNIT_DATE}</Text>
                                        }
                                    </>
                                }
                            </Flex>
                            {(item?.isExpire && vestingMap?.length === 0) ?
                                <Flex width="25%">
                                    <Text>
                                        0 {UNIT_DATE}
                                    </Text>
                                </Flex>
                                :
                                <>
                                    {new BigNumber(vestingMap[0]?.nextInterestPaymentDate * 1000).isNaN() ?
                                        <Flex width="25%">
                                            <Skeleton width="42px" />
                                        </Flex>
                                        :
                                        <Text width="25%">
                                            {Number(item?.denominations).toLocaleString("en", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })}
                                        </Text>
                                    }
                                </>
                            }
                        </Flex>
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            mt="0.5rem"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <Flex width={["20%", "25%", "25%", "25%"]}>
                                <Text fontSize="12px" color="textSubtle">
                                    LSSN:{" "}
                                </Text>

                                <Text>&nbsp;
                                    {(item?.isExpire && vestingMap?.length === 0) ?
                                        `0`
                                        :
                                        Number(yieldsReceived).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                            </Flex>
                            <Flex width={["28%", "25%", "25%", "25%"]} justifyContent="center">
                                <Text>
                                    {totalPrice.toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </Text>
                            </Flex>
                            <Flex width={["28%", "25%", "25%", "25%"]} justifyContent="center">
                                <Text>
                                    {totalVested}/{totalVestingMap}
                                </Text>
                            </Flex>
                            <WraperBtnSell>
                                {vestingMap?.length > 0 ?
                                    <>
                                        {currentTime > Number(vestingMap[0]?.nextInterestPaymentDate) * 1000 ? (
                                            <CsButtonReceiProfit
                                                disabled={!isTimeCanClaim}
                                                onClick={onProfitReceivedModal}
                                            >
                                                Nhận lợi suất
                                            </CsButtonReceiProfit>
                                        ) : (
                                            <CsButton disabled={item?.issueDate * 1000 > Date.now()} onClick={onSellModal}>Bán</CsButton>
                                        )}
                                    </>
                                    :
                                    <>
                                        {item?.isExpire &&
                                            <CsButton onClick={onReceivedAll}>Đáo hạn</CsButton>
                                        }
                                    </>
                                }
                            </WraperBtnSell>
                        </Flex>
                    </WrapItemMobile>
                </WrapperTableMobile>
            )}
        </>
    );
};

export default Row;

const CsTextMonth = styled(Text)`
    font-size: 10px;
    font-weight: 500;
    padding: 3px 15px;
    border-radius: 4px;
    letter-spacing: 0.04em;
    text-align: center;
    color: ${({ theme }) => theme.colors.textSubtle};
    border: 1px solid ${({ theme }) => theme.colors.background};
    @media screen and (max-width: 400px){
        padding: 3px 8px;
    }
`;

const WrapItemMobile = styled.div`
    padding: 0 12px 12px;
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    ${Text} {
        margin: 8px 0;
        font-size: 12px;
        font-weight: 600;
        &:last-child {
            text-align: right;
        }
    }
`;

const WrapperTableMobile = styled(Flex)`
    width: 100%;
    flex-direction: column;
`;

const WrapperTable = styled.div`
    width: 100%;
`;

const WrapperTableContent = styled(Flex)`
    flex-direction: column;
`;

const WrapItem = styled(Flex)`
    width: 100%;
    height: 100%;
    padding: 26px;
    height: 80px !important;
    align-items: center;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 24px;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    ${Text} {
        text-align: center;
        font-size: 14px;
        line-height: 19px;
    }
`;

const CsButton = styled(Button) <{ disabled?: boolean }>`
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    min-width: 130px;
    border-radius: 4px;
    background: transparent;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
    &:hover {
        background: ${({ theme, disabled }) => disabled ? "#E6E8ec" : theme.colors.primary};
        color: ${({ theme, disabled }) => disabled ? "#8C8D8E" : theme.colors.white};
        border: 1px solid transparent;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 36px;
        padding: 0;
    }
    @media screen and (max-width: 500px) {
        min-width: 90px;
    }
`;
const CsButtonReceiProfit = styled(Button)`
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    min-width: 130px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid transparent;
    transition: 0.5s;
    &:hover {
        background: transparent;
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 36px;
        padding: 0;
    }
    @media screen and (max-width: 500px) {
        min-width: 90px;
    }
`
const WraperBtnSell = styled(Flex)`
    justify-content: flex-end;
    width: 15%;
    height: 40px;
    @media screen and (max-width: 768px) {
        width: 25%;
    }
`;
