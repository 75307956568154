import { axiosClient } from "config/htttp";
import { chainKey } from "config";
import { ListItems } from "./type";


export const renderType = (type: string) => {
    if (type?.toLowerCase() === "buy") {
        return 1
    } return 2
}
export const fetchListHistory = async (account: string, itemOffset: number, pageActive: number, fromData: string, toData: string, searchData: string, orderTypeData: string, chainId: number): Promise<ListItems> => {
    try {
        const response = await axiosClient.get("/trade-history", {
            headers: { "Content-Type": "application/json", 'Accept': '*' },
            params: {
                userWalletAddress: account,
                pageSize: itemOffset,
                pageIndex: pageActive + 1,
                search: searchData,
                orderType: orderTypeData,
                fromDate: fromData,
                toDate: toData,
                chainKey: chainKey(chainId)
            }
        });
        const dataTemp = response?.data?.data

        const result = []
        for (let index = 0; index < dataTemp?.items?.length; index++) {
            result.push(
                {
                    historyId: dataTemp?.items[index]?.tokenId,
                    dayTrading: dataTemp.items[index]?.createdAt,
                    transaction: dataTemp.items[index]?.transactionNumber,
                    jointsOrderQuantity: dataTemp.items[index]?.tradedQuantity,
                    totalTxValue: Number(dataTemp.items[index]?.totalValue),
                    status: dataTemp.items[index]?.status,
                    seller: dataTemp.items[index]?.sellerAddress,
                    buyer: dataTemp.items[index]?.buyerAddress,
                    etfContract: dataTemp.items[index]?.etfContract,
                    eventType: dataTemp.items[index]?.eventType,
                    isSeller: account?.toLowerCase() === dataTemp.items[index]?.sellerAddress.toLowerCase()
                }
            )
        }
        return {
            listItemHistory: result,
            totalItem: response?.data?.data?.totalItems
        }
    }
    catch (error) {
        console.log(error)
        return {
            listItemHistory: [],
            totalItem: 0
        }
    }
}