
import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, OpenNewIconFundgo, Text } from "@pancakeswap/uikit";
import React from "react";
import { GetItemHistory } from "state/history";
import styled from "styled-components";
import { convertDate } from "utils/converDate";
import { renderAddressByType, renderStatus, renderType } from "utils/renderProfit";
import BigNumber from "bignumber.js";
import { CsModal, CsTextMobile, CustomLink, TextTitle } from "../styles";

interface DetailProps {
    onDismiss?: () => void;
    csAddress?: (address: string) => string;
    handleOpenLink?: (address, type) => void;
}

const DetailModal: React.FC<DetailProps> = ({ onDismiss, csAddress, handleOpenLink }) => {
    const itemHistoryValue = GetItemHistory();

    const { t } = useTranslation();
    const { eventType, jointsOrderQuantity, dayTrading, status, isSeller, seller, buyer ,totalTxValue} = itemHistoryValue;
    function handleClickOut() {
        // router.push(`/edit/${id}`)
        onDismiss();
    }


    return (
        <CsModal title="" onDismiss={onDismiss}>
            <Flex width="100%" height="auto" flexDirection="column" style={{ gap: "10px" }} mt="1rem">
                <Flex width="100%" justifyContent="center">
                    <TextTitle bold>Thông tin chi tiết</TextTitle>
                </Flex>

                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Loại lệnh</CsTextMobile>
                    {renderType(isSeller, eventType)}
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Ngày giao dịch</CsTextMobile>
                    <CsTextMobile bold>{convertDate(dayTrading)}</CsTextMobile>
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Số lượng</CsTextMobile>
                    <CsTextMobile bold>{jointsOrderQuantity}</CsTextMobile>
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Tổng giá trị giao dịch (VND)</CsTextMobile>
                    <CsTextMobile bold>
                        {Number(totalTxValue).toLocaleString("en", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })}
                    </CsTextMobile>
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">Trạng thái</CsTextMobile>
                    <CsTextMobile>
                        {renderStatus(status)}
                    </CsTextMobile>
                </Flex>
                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                    <CsTextMobile color="textSubtle">
                        Địa chỉ ví
                        <br /> giao dịch thỏa thuận
                    </CsTextMobile>
                    <CsTextMobile bold>
                        <CustomLink
                            style={{ textAlign: "right" }}
                            onClick={() => handleOpenLink(renderAddressByType(isSeller, buyer, seller), "address")}
                        >
                            <Text>{csAddress(renderAddressByType(isSeller, buyer, seller))}</Text>
                            <OpenNewIconFundgo />
                        </CustomLink>
                    </CsTextMobile>
                </Flex>
                <Flex mt="1rem">
                    <CsButton onClick={handleClickOut} height="42px" width="100%" style={{ borderRadius: "8px" }}>
                        Thoát
                    </CsButton>
                </Flex>
            </Flex>
        </CsModal>
    );
};

const CsButton = styled(Button)`
    transition: 0.5s;
    &:hover {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
`;

export default DetailModal;
