import { Button, Flex, HelpIconCcqIcon2, NoticeIcon, Text, useTooltip } from "@pancakeswap/uikit";
import { LightTooltip3 } from "components/ToolTip3";
import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { CsItemWarning } from "views/MarketPlace/style";

interface optionsProps {
    handleChoose: (id: number, addresses: string, buys) => void;
    addresses?: string
}
type HandleProps = Items & optionsProps;

const ItemMobile: React.FC<HandleProps> = ({
    amount,
    dayDiff,
    id,
    unitPrice,
    handleChoose,
    price,
    interVestTerm,
    interVestTermRate,
    vestingMap,
    paused,
    addresses,
    buys
}) => {

    const totalVested = useMemo(() => {
        return vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [vestingMap])
    const totalVestingMap = useMemo(() => {
        return vestingMap?.length
    }, [vestingMap])

    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        <CsTextTootip style={{ color: "red" }}>CCQ đang tạm khóa, vui lòng quay lại sau!</CsTextTootip>,
        { placement: "top-end", tooltipOffset: [20, 10] }
    );

    return (
        <WrapperBuyItem>
            <CsFlex>
                <CsItem>
                    <CsFlexHeader>
                        <LightTooltip3 title="Số lượng">
                            <Flex style={{ cursor: 'pointer' }}>
                                <Flex color="#C7C9CA" mr="4px" size={12}>
                                    SL
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsText mr='4px' ml="2px">
                            {` ${Number(amount).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}`}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <LightTooltip3 title="Kỳ trả lợi suất">
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    KTLS
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsTextMonth>
                            {interVestTerm} tháng
                        </CsTextMonth>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <LightTooltip3 title="Lợi suất ngày còn lại">
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    LSNCL
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsText mr='4px' ml="2px">
                            {interVestTermRate}%
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <LightTooltip3 title="Đơn giá">
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    ĐG
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsText mr='4px' ml="2px">
                            {Number(unitPrice).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItemWarning>
                    {paused &&
                        <>
                            <ReferenceElement ref={targetRef}>
                                <NoticeIcon />
                            </ReferenceElement>
                            <>{tooltipVisible && tooltip}</>
                        </>
                    }
                </CsItemWarning>
            </CsFlex>
            <CsFlex>
                <CsItem>
                    <CsFlexHeader>
                        <LightTooltip3 title="Mệnh giá">
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    MG
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsText mr='4px' ml="2px">
                            {Number(price).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <LightTooltip3 title="Ngày còn lại">
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    NCL
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsText mr='4px' ml="2px">
                            {`${dayDiff} ${UNIT_DATE}`}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <LightTooltip3 title="Kỳ đã nhận">
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    KĐN
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </LightTooltip3>
                        <CsText mr='2px'>
                            {totalVested}/{totalVestingMap}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsButton disabled={paused} onClick={() => handleChoose(id, addresses, buys)}>Chọn</CsButton>
                </CsItem>
            </CsFlex>
        </WrapperBuyItem>
    );
};

export default ItemMobile;

const CsTextMonth = styled(Text)`
    font-size: 10px;
    font-weight: 600;
    padding: 3px 20px;
    border-radius: 4px;
    letter-spacing: 0.04em;
    text-align: center;
    @media screen and (max-width: 475px) {
        padding: 3px 8px;
    }
`;
const CsFlex = styled(Flex)`
    width: 100%;
    padding: 6px 0;
    align-items: center;
    justify-content: space-between;
`;
const CsText = styled(Text)`
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    &:first-child {
        text-align: left;
    }
`;

const CsItem = styled(Flex)`
    width: 25%;
    height: 42px;
    align-items: center;
    font-size:12px;
`
const CsButton = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};

    border-radius: 4px;
    min-width: 90px;
    height: 32px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
    @media screen and (max-width: 475px) {
        min-width: 75px;
    }

    &:hover {
        border: 1px solid transparent;
        background: "#447290";
        /* color: ${({ theme }) => theme.colors.white}; */
    }
`;

const WrapperBuyItem = styled.div`
    padding: 8px 16px;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.textDisabled};
`;

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 2px;
`
const CsTextTootip = styled(Text)`
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;
const ReferenceElement = styled.div`
    display: inline-block;
    cursor: pointer;
`;